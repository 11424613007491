import React from "react";

////////////////////////////////////////////////////////////////////////////////
///////////////////////////                            /////////////////////////
///////////////////////////  Reading Rooster Imports  //////////////////////////
///////////////////////////                          ///////////////////////////
////////////////////////////////////////////////////////////////////////////////

// Market Share Page
const roosterHomepage = React.lazy(() =>
  import("./pages/Rooster Homepage/index")
);

// Market Share Page
const roosterMarketshareAnalyzer = React.lazy(() =>
  import("./pages/Rooster Marketshare Analyzer/index")
);

// Market Share Page
const roosterResearchFramework = React.lazy(() =>
  import("./pages/Rooster Research Framework/index")
);

// Mentor page
const roosterMentorProgram = React.lazy(() =>
  import("./pages/Rooster Mentor Program/index")
);

// Login page
const roosterLogin = React.lazy(() =>
  import("./pages/Rooster Login/PageCoverLogin")
);

// Signup Page
const roosterSignup = React.lazy(() =>
  import("./pages/Rooster Signup/PageCoverSignup")
);

// Forgot Email Page
const roosterRecoverPassword = React.lazy(() =>
  import("./pages/Rooster Recover Password/PageCoverRePassword")
);

// Forgot Email Page
const roosterContact = React.lazy(() =>
  import("./pages/Rooster Contact/PageContactDetail")
);

const rooster404 = React.lazy(() => import("./pages/Rooster 404/PageError"));

///////////////////////////////////////////////////////////////////////////
///////////////////////////                    ////////////////////////////
///////////////////////////  Template Imports  ////////////////////////////
///////////////////////////                    ////////////////////////////
///////////////////////////////////////////////////////////////////////////

// Root Include
const Root = React.lazy(() => import("./pages/Home/indexRoot"));
const PageContactDetail = React.lazy(() =>
  import("./pages/Pages/Contact/PageContactDetail")
);

//Special
const PageComingSoon = React.lazy(() =>
  import("./pages/Pages/Special/PageComingSoon")
);
const PageComingSoon2 = React.lazy(() =>
  import("./pages/Pages/Special/page-comingsoon2")
);
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const PageMaintenance = React.lazy(() =>
  import("./pages/Pages/Special/PageMaintenance")
);

//User Pages
const PageLogin = React.lazy(() => import("./pages/Pages/User/PageLogin"));
const PageCoverLogin = React.lazy(() =>
  import("./pages/Pages/User/PageCoverLogin")
);
const PageCoverRePassword = React.lazy(() =>
  import("./pages/Pages/User/PageCoverRePassword")
);
const PageCoverSignup = React.lazy(() =>
  import("./pages/Pages/User/PageCoverSignup")
);
const PageRecoveryPassword = React.lazy(() =>
  import("./pages/Pages/User/PageRecoveryPassword")
);
const PageSignup = React.lazy(() => import("./pages/Pages/User/PageSignup"));

// Import all components
const SaasOnepage = React.lazy(() => import("./pages/Saas Onepage/index"));
const Customer = React.lazy(() => import("./pages/Customer/index"));
const Job = React.lazy(() => import("./pages/Job/index"));
const Software = React.lazy(() => import("./pages/Software/index"));
const Cryptocurrency = React.lazy(() => import("./pages/Cryptocurrency/index"));
const Payments = React.lazy(() => import("./pages/Payments/index"));
const IndexCarRiding = React.lazy(() => import("./pages/Car Ride/index"));
const IndexclassicSaas = React.lazy(() => import("./pages/Classic Saas/index"));
const ClassicApp = React.lazy(() =>
  import("./pages/Classic Application/index")
);
const Main = React.lazy(() => import("./pages/Home/indexMain"));
const Agency = React.lazy(() => import("./pages/Agency/index"));
const Saas = React.lazy(() => import("./pages/Saas/index"));
const Apps = React.lazy(() => import("./pages/Apps/index"));
const Studio = React.lazy(() => import("./pages/Studio/index"));
const Business = React.lazy(() => import("./pages/Rooster Homepage/index"));
const Marketing = React.lazy(() => import("./pages/Marketing/index"));
const Hotel = React.lazy(() => import("./pages/Hotel/index"));
const ModernBusiness = React.lazy(() => import("./pages/ModernBusiness/index"));
const Coworking = React.lazy(() => import("./pages/Coworking/index"));
const CloudHosting = React.lazy(() => import("./pages/CloudHosting/index"));
const Event = React.lazy(() => import("./pages/Event/index"));
const Course = React.lazy(() => import("./pages/Course/index"));
const Personal = React.lazy(() => import("./pages/Personal/index"));
const SingleProduct = React.lazy(() => import("./pages/SingleProduct/index"));
const Enterprise = React.lazy(() => import("./pages/Enterprise/index"));
const Portfolio = React.lazy(() => import("./pages/Portfolio/index"));
const Services = React.lazy(() => import("./pages/Services/index"));

//Docs
const ChangeLog = React.lazy(() => import("./pages/Docs/ChangeLog"));
const Components = React.lazy(() => import("./pages/Docs/Components"));
const Documentation = React.lazy(() => import("./pages/Docs/Documentation"));
const Widget = React.lazy(() => import("./pages/Docs/widget"));

const PageAboutUs = React.lazy(() => import("./pages/Pages/PageAboutUs"));
const PagePricing = React.lazy(() => import("./pages/Pages/PagePricing"));
const PageServices = React.lazy(() => import("./pages/Pages/PageServices"));
const PageTeam = React.lazy(() => import("./pages/Pages/PageTeam"));

//Account
const PageProfile = React.lazy(() =>
  import("./pages/Pages/Account/page-profile")
);
const PageProfileEdit = React.lazy(() =>
  import("./pages/Pages/Account/page-profile-edit")
);
const PageInvoice = React.lazy(() =>
  import("./pages/Pages/Account/page-invoice")
);

//Career
const PageJobsSidebar = React.lazy(() =>
  import("./pages/Pages/Careers/page-jobs-sidebar")
);
const PageJobDetail = React.lazy(() =>
  import("./pages/Pages/Careers/page-job-detail")
);
const PageJob = React.lazy(() => import("./pages/Pages/Careers/PageJob"));
const PageJobApply = React.lazy(() =>
  import("./pages/Pages/Careers/page-job-apply")
);
const PageJobCompany = React.lazy(() =>
  import("./pages/Pages/Careers/page-job-company")
);
const PageJobCandidate = React.lazy(() =>
  import("./pages/Pages/Careers/page-job-candidate")
);

//Blog
const PageBlog = React.lazy(() => import("./pages/Pages/Blog/PageBlog"));
const PageBlogDetail = React.lazy(() =>
  import("./pages/Pages/Blog/PageBlogDetail")
);
const PageBlogSidebar = React.lazy(() =>
  import("./pages/Pages/Blog/PageBlogSidebar")
);

//Work
const PageWork = React.lazy(() => import("./pages/Pages/Work/PageWork"));
const PageWorkDetail = React.lazy(() =>
  import("./pages/Pages/Work/PageWorkDetail")
);

//Utility
const PagePrivacy = React.lazy(() =>
  import("./pages/Pages/Utility/PagePrivacy")
);
const PageTerms = React.lazy(() => import("./pages/Pages/Utility/PageTerms"));

//Contact
const PageContactOne = React.lazy(() =>
  import("./pages/Pages/Contact/PageContactOne")
);
const PageContactThree = React.lazy(() =>
  import("./pages/Pages/Contact/PageContactThree")
);
const PageContactTwo = React.lazy(() =>
  import("./pages/Pages/Contact/PageContactTwo")
);

const routes = [
  //routes without Layout

  //Contct withour layout
  {
    path: "/page-contact-detail",
    component: PageContactDetail,
    isWithoutLayout: false,
    exact: true,
  },

  //Special Pages
  {
    path: "/page-comingsoon",
    component: PageComingSoon,
    isWithoutLayout: true,
    exact: true,
  },
  {
    path: "/page-comingsoon2",
    component: PageComingSoon2,
    isWithoutLayout: true,
    exact: true,
  },
  {
    path: "/page-error",
    component: PageError,
    isWithoutLayout: true,
    exact: true,
  },
  {
    path: "/page-maintenance",
    component: PageMaintenance,
    isWithoutLayout: true,
    exact: true,
  },

  //User Pages
  {
    path: "/page-login",
    component: PageLogin,
    isWithoutLayout: true,
    exact: true,
  },
  {
    path: "/page-signup",
    component: PageSignup,
    isWithoutLayout: true,
    exact: true,
  },
  {
    path: "/page-cover-login",
    component: PageCoverLogin,
    isWithoutLayout: true,
    exact: true,
  },
  {
    path: "/page-recovery-password",
    component: PageRecoveryPassword,
    isWithoutLayout: true,
    exact: true,
  },
  {
    path: "/page-cover-re-password",
    component: PageCoverRePassword,
    isWithoutLayout: true,
    exact: true,
  },
  {
    path: "/page-cover-signup",
    component: PageCoverSignup,
    isWithoutLayout: true,
    exact: true,
  },

  // Landings
  { path: "/index-onepage", component: SaasOnepage, exact: true },
  { path: "/index-customer", component: Customer, exact: true },
  { path: "/index-job", component: Job, exact: true },
  { path: "/index-software", component: Software, exact: true },
  { path: "/index-crypto", component: Cryptocurrency, exact: true },
  { path: "/index-payments", component: Payments, exact: true },
  { path: "/index-car-riding", component: IndexCarRiding, exact: true },
  { path: "/index-classic-saas", component: IndexclassicSaas, exact: true },
  { path: "/index-classic-app", component: ClassicApp, exact: true },
  { path: "/index-saas", component: Saas, exact: true },
  { path: "/index-apps", component: Apps, exact: true },
  { path: "/index-agency", component: Agency, exact: true },
  { path: "/index-studio", component: Studio, exact: true },
  { path: "/index-business", component: Business, exact: true },
  { path: "/index-marketing", component: Marketing, exact: true },
  { path: "/index-hotel", component: Hotel, exact: true },
  { path: "/index-modern-business", component: ModernBusiness, exact: true },
  { path: "/index-coworking", component: Coworking, exact: true },
  { path: "/index-cloud-hosting", component: CloudHosting, exact: true },
  { path: "/index-event", component: Event, exact: true },
  { path: "/index-course", component: Course, exact: true },
  { path: "/index-personal", component: Personal, exact: true },
  { path: "/index-single", component: SingleProduct, exact: true },
  { path: "/index-enterprise", component: Enterprise, exact: true },
  { path: "/index-portfolio", component: Portfolio, exact: true },
  { path: "/index-services", component: Services, exact: true },

  { path: "/page-aboutus", component: PageAboutUs, exact: true },
  { path: "/page-pricing", component: PagePricing, exact: true },
  { path: "/page-services", component: PageServices, exact: true },
  { path: "/page-team", component: PageTeam, exact: true },

  //Utility
  { path: "/page-terms", component: PageTerms, exact: true },
  { path: "/page-privacy", component: PagePrivacy, exact: true },

  //Page Work
  { path: "/page-work", component: PageWork, exact: true },
  { path: "/page-work-detail", component: PageWorkDetail, exact: true },

  //Page Profile
  { path: "/page-profile", component: PageProfile, exact: true },
  { path: "/page-profile-edit", component: PageProfileEdit, exact: true },
  { path: "/page-invoice", component: PageInvoice, exact: true },

  //Page Job
  { path: "/page-job", component: PageJob, exact: true },
  { path: "/page-job-apply", component: PageJobApply, exact: true },
  { path: "/page-job-detail", component: PageJobDetail, exact: true },
  { path: "/page-jobs-sidebar", component: PageJobsSidebar, exact: true },
  { path: "/page-job-company", component: PageJobCompany, exact: true },
  { path: "/page-job-candidate", component: PageJobCandidate, exact: true },

  //Page Blog
  { path: "/page-blog", component: PageBlog, exact: true },
  { path: "/page-blog-detail", component: PageBlogDetail, exact: true },
  { path: "/page-blog-sidebar", component: PageBlogSidebar, exact: true },

  //Page Contact
  { path: "/page-contact-one", component: PageContactOne, exact: true },
  { path: "/page-contact-three", component: PageContactThree, exact: true },
  { path: "/page-contact-two", component: PageContactTwo, exact: true },

  //Docs
  { path: "/changelog", component: ChangeLog, exact: true },
  { path: "/components", component: Components, exact: true },
  { path: "/documentation", component: Documentation, exact: true },
  { path: "/widget", component: Widget, exact: true },

  //Index Main
  { path: "/main", component: Main, exact: true },

  //Template Overview
  { path: "/template", component: Root, isWithoutLayout: true, exact: true },

  /////////////////////////////////////////////////////////////////////////
  ///////////////////////////                  ////////////////////////////
  ///////////////////////////  Rooster Routes  ////////////////////////////
  ///////////////////////////                  ////////////////////////////
  /////////////////////////////////////////////////////////////////////////

  // Contact
  { path: "/contact", component: roosterContact, exact: true },

  // Marketshare Analyzer
  {
    path: "/marketshare-analyzer",
    component: roosterMarketshareAnalyzer,
    exact: true,
  },

  // SEO Framework
  {
    path: "/market-research-framework",
    component: roosterResearchFramework,
    exact: true,
  },

  // Young professionals
  { path: "/mentor-program", component: roosterMentorProgram, exact: true },

  // Login to Reading Rooster backend
  {
    path: "/login",
    component: roosterLogin,
    isWithoutLayout: true,
    exact: true,
  },

  // Signup page for Reading Rooster
  {
    path: "/signup",
    component: roosterSignup,
    isWithoutLayout: true,
    exact: true,
  },

  // Recover Password
  { path: "/recoverpassword", component: roosterRecoverPassword, exact: true },

  //Root
  { path: "/", component: roosterHomepage, exact: true },

  { component: rooster404, exact: false },
];

export default routes;
